var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, size: _vm.size, "label-width": _vm.labelWidth },
      nativeOn: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c("c-form-row", {
        attrs: { list: _vm.models, readOnly: _vm.readOnly },
        scopedSlots: _vm._u([
          {
            key: "area",
            fn: function(scope) {
              return [
                _c(
                  "el-form-item",
                  _vm._b({}, "el-form-item", scope.data.itemProps, false),
                  [
                    _c("areaSelector", {
                      on: { onSelectByHand: _vm.onSelectByHand },
                      model: {
                        value: _vm.form.area,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "area", $$v)
                        },
                        expression: "form.area"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "map",
            fn: function() {
              return [
                _c("TencentMap", {
                  ref: "TencentMap",
                  attrs: { center: _vm.center },
                  on: { change: _vm.onMapChange }
                })
              ]
            },
            proxy: true
          },
          {
            key: "address_detail",
            fn: function(scope) {
              return [
                _c(
                  "el-form-item",
                  _vm._b({}, "el-form-item", scope.data.itemProps, false),
                  [
                    _c("addressSelector", {
                      ref: "addressSelector",
                      attrs: { region: _vm.city },
                      on: { change: _vm.onAddressSelect }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }