/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-10-13 09:49:25
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 21:40:29
 */
/* eslint-disable no-unused-vars */
// 组件类型
import { genModels } from '@/plugins/widget/c-form/rules';

/**
                                                            * ruleTypes: 规则类型
                                                            * genRules： rule 生成函数
                                                            * ctx： Vue实例
                                                            */
export var models = genModels(function (genRules, ctx) {return [
  {
    prop: 'title',
    itemProps: {
      label: '机器编码：',
      rules: [
      genRules('require', '请输入！')] },


    comprops: {
      // 组件 的绑定属性
      props: {
        clearable: true,
        maxLength: 20 } } },



  {
    prop: 'head',
    type: 'selector',
    itemProps: {
      label: '所属加盟商：',
      rules: [
      genRules('require', '请选择！')] },


    comprops: {
      props: {
        type: 'sxxx' } } },



  {
    span: 12,
    prop: 'region',
    itemProps: {
      label: '机器位置：',
      rules: [
      genRules('require', '请选择')] },


    comprops: {} },


  {
    span: 12,
    prop: 'address',
    itemProps: {
      label: '详细地址：',
      rules: [
      genRules('require', '请输入关键字！')] },


    comprops: {
      props: {
        clearable: true,
        maxLength: 30 } } },



  {
    prop: 'map' }];});




// form 默认值
export var formData = function formData() {return {};};